import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home/Index.vue";
import Solution from "../views/solution/Index.vue";
import Case from "../views/case/Index.vue";
import About from "../views/about/Index.vue";
import Details from "../views/home/DetailPage.vue";
// import PageNotFound from "../views/PageNotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/solution",
    name: "solution",
    component: Solution
  },
  {
    path: "/case",
    name: "case",
    component: Case
  },
  {
    path: "/about",
    name: "about",
    component: About
  },
  {
    path: "/details",
    name: "details",
    component: Details
  },
  {
    path: "*",
    name: "home",
    component: Home
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
