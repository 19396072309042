<template>
  <div class="slogan">
    <Header :absolute="true"></Header>
    <!-- <div class="arrows">
      <img
        class="arrow-item arrow-item-prev"
        src="./imgs/arrow.png"
        @click="prev"
      />
      <img class="arrow-item" src="./imgs/arrow.png" @click="next" />
    </div> -->
    <div class="swipper">
      <transition name="fade">
        <!-- <div class="swipper-item swipper-item1" v-if="currentIndex == 0" /> -->
        <img
          class="swipper-item"
          src="./imgs/section1-bg3.png"
          v-if="currentIndex == 0"
        />
      </transition>
      <transition name="fade">
        <!-- <div class="swipper-item swipper-item2" v-if="currentIndex == 1" /> -->
        <img
          class="swipper-item"
          src="./imgs/section1-bg2.png"
          v-if="currentIndex == 1"
        />
      </transition>
      <transition name="fade">
        <!-- <div class="swipper-item swipper-item3" v-if="currentIndex == 2" /> -->
        <img
          class="swipper-item"
          src="./imgs/section1-bg1.png"
          v-if="currentIndex == 2"
        />
      </transition>

      <div class="outmost">
        <!-- <div class = "imgdiv"></div>
        <div class = "imgdiv"></div>
        <div class = "imgdiv"></div> -->
        <div
          :class="currentIndex == 0 ? 'imgdiv imgdivsel' : 'imgdiv'"
          @click="handleIndex(0)"
        ></div>
        <div
          :class="currentIndex == 1 ? 'imgdiv imgdivsel' : 'imgdiv'"
          @click="handleIndex(1)"
        ></div>
        <div
          :class="currentIndex == 2 ? 'imgdiv imgdivsel' : 'imgdiv'"
          @click="handleIndex(2)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../Header";

export default {
  components: {
    Header
  },
  data() {
    return {
      currentIndex: 0
    };
  },
  watch: {
    currentIndex() {
      if (this._ind) {
        clearTimeout(this._ind);
        this._ind = setTimeout(this.next, 5000);
      }
    }
  },
  methods: {
    goAnimation() {},
    removeAnimation() {},
    handleIndex(index) {
      this.currentIndex = index;
    },
    next() {
      this.currentIndex++;
      if (this.currentIndex > 2) {
        this.currentIndex = 0;
      }
    },
    prev() {
      this.currentIndex--;
      if (this.currentIndex < 0) {
        this.currentIndex = 2;
      }
    }
  },
  created() {
    this._ind = setTimeout(this.next, 5000);
  },
  beforeDestroy() {
    if (this._ind) {
      clearTimeout(this._ind);
    }
  }
};
</script>

<style lang="scss" scoped>
.slogan {
  position: relative;
  height: 6.4rem;
  .arrows {
    position: absolute;
    z-index: 2;
    left: 1.37rem;
    top: 3.94rem;
    .arrow-item {
      display: inline-block;
      vertical-align: middle;
      width: 0.16rem;
      margin-right: 0.1rem;
      opacity: 0.5;
      transition: all 0.2s;
      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    }
    .arrow-item-prev {
      transform: rotate(180deg);
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    // transition: opacity 0.5s;
    transition: 0.5s linear;
  }
  .fade-enter {
    // opacity: 0;
    transform: translateX(100%);
  }
  // .fade-enter-to,
  .fade-leave-to {
    transform: translateX(-100%);
  }
  .swipper {
    position: relative;
    height: 6.4rem;

    .swipper-item {
      position: absolute;
      top: 0.8rem;
      left: 0;
      right: 0;
      height: 5.6rem;
      width: 100%;
      object-fit: cover;
    }
    .swipper-item1 {
      background: url(./imgs/section1-bg3.png) no-repeat;
      background-size: 100% 100%;
    }
    .swipper-item2 {
      background: url(./imgs/section1-bg2.png) no-repeat;
      background-size: 100% 100%;
    }
    .swipper-item3 {
      background: url(./imgs/section1-bg1.png) no-repeat;
      background-size: 100% 100%;
    }
    .title {
      font-size: 0.34rem;
      text-align: center;
      margin-top: 1rem;
    }
    .items {
      font-size: 0;
      text-align: center;
      margin-top: 0.3rem;
      .item {
        display: inline-block;
        font-size: 0.15rem;
        line-height: 0.15rem;
        vertical-align: middle;
        margin-right: 0.1rem;
        padding-right: 0.1rem;
        border-right: 1px solid #fff;
        &:last-child {
          margin-right: 0;
          border: none;
        }
      }
    }
  }

  .outmost {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 2.8rem;
    right: 10%;
  }

  .imgdiv {
    height: 0.15rem;
    width: 0.04rem;
    background-color: #ffffff;
    background-color: #ffffff;
    opacity: 0.2;
    // margin: 0 0.03rem; /* 可选，用于子div之间的间隔 */
    margin-top: 0.03rem;
    cursor: pointer;
  }

  .imgdivsel {
    background-color: #ffffff;
    opacity: 1;
  }
}
</style>
