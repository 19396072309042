<template>
  <div class="section5">
    <span>咨询电话：18248493329</span>
    <img src="./imgs/section5-bg.png" @click="goMap" />
  </div>
</template>

<script>
export default {
  methods: {
    goMap() {
      window.open("https://surl.amap.com/597gY2PQ8rw");
    }
  }
};
</script>

<style lang="scss" scoped>
.section5 {
  position: relative;
  height: 4.8rem;
  background-color: #fff;
}
span {
  height: 0.8rem;
  color: #000;
  font-size: 0.15rem;
  line-height: 0.8rem;
  margin-left: 1.2rem;
  font-weight: bold;
}
img {
  position: relative;
  margin: 0 1.2rem;
  cursor: pointer;
  height: 3.35rem;
}
</style>
