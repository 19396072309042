<template>
  <div class="home">
    <div class="section">
      <Section1 ref="home1"></Section1>
      <ScrollTip></ScrollTip>
    </div>
    <div class="section">
      <Section2 ref="home2"></Section2>
      <ScrollTip></ScrollTip>
    </div>
    <div class="section">
      <Section4></Section4>
      <ScrollTip></ScrollTip>
    </div>
    <div class="section">
      <Section5></Section5>
      <ScrollTip></ScrollTip>
    </div>
    <div class="section">
      <Section3 ref="home4"></Section3>
    </div>
  </div>
</template>

<script>
import ScrollTip from "../ScrollTip";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";

export default {
  name: "home",
  components: {
    Section1,
    ScrollTip,
    Section2,
    Section3,
    Section4,
    Section5
  },
  mounted() {
    const ua = navigator.userAgent;
    const ipad = ua.match(/(iPad).*OS\s([\d_]+)/);
    const isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/);
    const isAndroid = ua.match(/(Android)\s+([\d.]+)/);
    const isMobile = isIphone || isAndroid;
    let vm = this;
    if (!isMobile) {
          this._fullPage = new window.fullpage(this.$el, {
            anchors: ["section1", "section2", "section4", "section5", "section3"],
            scrollOverflow: true,
            scrollingSpeed: 700,
            afterLoad: function(preAnchor, curAnchor) {
              // vm._fullPage.test.options.scrollOverflow = true;
              if (curAnchor.index == 0) {
                vm.$refs.home1.goAnimation();
              } else if (curAnchor.index == 1) {
                vm.$refs.home2.goAnimation();
              } else if (curAnchor.index == 4) {
                vm.$refs.home4.goAnimation();
              } else if (curAnchor.index == 3) {
                // vm._fullPage.test.options.scrollOverflow = false;
              }
            },
            onLeave: function(leaveAnchor) {
              if (leaveAnchor.index == 0) {
                vm.$refs.home1.removeAnimation();
              } else if (leaveAnchor.index == 1) {
                vm.$refs.home2.removeAnimation();
              } else if (leaveAnchor.index == 4) {
                vm.$refs.home4.removeAnimation();
              }
            }
          });
    }
  },
  beforeDestroy() {
    if (this._fullPage) {
      this._fullPage.destroy("all");
    }
  }
};
</script>

<style lang="scss" scoped>
.section {
  position: relative;
}
</style>
