<template>
  <div class="section3">
    <div class="banner"></div>
    <div id="section3Title" class="title-ani">品牌使命</div>
    <div id="section3Desc" class="desc-ani">
      <div>不断创新、积极进取，</div>
      <div>为每个家庭带来更优质的产品</div>
      <div>和更温暖的服务</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.section3 {
  position: relative;
  height: 5.8rem;
  background: #fff;
  .banner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5.8rem;
    background: url(./imgs/section3-bg-1.png) no-repeat;
    background-size: 100% 100%;
  }

  .title-ani {
    position: absolute; /* 文字的绝对定位 */
    transform: translate(-50%, -50%); /* 使文字居中定位 */
    top: 30%; /* 放置在容器的垂直中间 */
    left: 45%; /* 放置在容器的水平中间 */
    font-size: 0.14rem; /* 文字大小 */
    width: 9%;
    color: white; /* 文字颜色 */
    border: 1px solid #fff;
    border-radius: 20px;
    text-align: center; /* 文字居中 */
    text-shadow: 1px 3px 5px #666;
    opacity: 0;
  }

  .desc-ani {
    text-shadow: 1px 3px 5px #666;
    position: absolute; /* 文字的绝对定位 */
    transform: translate(-50%, -50%); /* 使文字居中定位 */
    top: 40%; /* 放置在容器的垂直中间 */
    left: 28%; /* 放置在容器的水平中间 */
    font-size: 0.3rem; /* 文字大小 */
    width: 45%;
    text-align: center;
    color: white; /* 文字颜色 */
    text-align: center; /* 文字居中 */
    opacity: 0;
  }

  .animationTitle {
    animation: move-text 0.8s forwards;
  }

  .animationDesc {
    animation: move-text 2.5s forwards;
  }

  @keyframes move-text {
    0% {
      transform: translateY(0);
      opacity: 0;
    }
    100% {
      transform: translateY(-40px);
      opacity: 1;
    }
    to {
      opacity: 1;
    }
  }
}
</style>

<script>
export default {
  created() {
    // console.log("onCreate");
    // window.addEventListener('scroll', this.onScrollFunc);
  },
  beforeDestroy() {
    // console.log("beforeDestroy");
    // window.removeEventListener('scroll', this.onScrollFunc);
  },
  methods: {
    goAnimation() {
      var elementAni = document.getElementById("section3Title");
      elementAni.classList.add("animationTitle");

      var elementAniText = document.getElementById("section3Desc");
      elementAniText.classList.add("animationDesc");
    },
    removeAnimation() {
      var elementAni = document.getElementById("section3Title");
      elementAni.classList.remove("animationTitle");

      var elementAniText = document.getElementById("section3Desc");
      elementAniText.classList.remove("animationDesc");
    }
  }
};
</script>
