import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import Rem from "./lib/rem";
Rem();

Vue.config.productionTip = false;

router.afterEach(() => {
  window.scrollTo(0, 0);
});

Vue.use(ElementUI);

new Vue({
  router,
  render: h => h(App),
  //添加到这里,这里的render-event和vue.config.js里面的renderAfterDocumentEvent配置名称一致
  mounted() {
    // document.dispatchEvent(new Event("render-event"));
  }
}).$mount("#app");

let isMobile = navigator.userAgent.match(
  /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
);
if (isMobile) {
  window.location.href = "//m.u2live.cn/";
}

// const ipCallback = () => {
//   if (window.returnCitySN) {
//     const ip = window.returnCitySN["cip"];
//     // const city = window.returnCitySN["cname"];
//     if (ip == "101.68.87.98") {
//       //101.68.87.98
//       window.location.href = "http://coin.zhichenghz.com/";
//     }
//   }
// };
// let el = document.getElementById("ipagent");
// if (!el) {
//   el = document.createElement("script");
//   el.setAttribute("id", "ipagent");
//   el.setAttribute("src", "//pv.sohu.com/cityjson?ie=utf-8");
//   el.onload = () => {
//     ipCallback();
//   };
//   document.body.appendChild(el);
// } else {
//   ipCallback();
// }
