<template>
  <div class="section4">
    <div class="newsvideo">
      <video class="video_div" controls>
        <source src="./imgs/video_01.mp4" type="video/mp4" />
      </video>
      <!-- <video
          ref="videoPlayer"
          class="video-js vjs-default-skin video_div"
          controls
          preload="auto"
          poster="./imgs/video_bg.jpg"
          data-setup="{}"
        >
        <source src="./imgs/video_01.mp4" type="video/mp4" />
      </video> -->
    </div>
  </div>
</template>

<script>
// import videojs from 'video.js';
// import 'video.js/dist/video-js.css';

export default {
  name: "VideoPlayer",
  components: {},
  data() {
    return {};
  },
  watch: {},
  methods: {},
  created() {},
  mounted() {
    // this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
    //   console.log('Player is ready');
    // });
  },
  beforeDestroy() {
    // if (this.player) {
    //   this.player.dispose();
    // }
  }
};
</script>

<style lang="scss" scoped>
.section4 {
  // height: 100%;
  height: 5.5rem;
  position: relative;
  background: #f9f9fd;
  padding: 0 0.9rem;
  padding-top: 0.3rem;

  .newsvideo {
    position: absolute;
    width: 75%;
    top: 2%;
    //background: url("./imgs/section4-bg.png") no-repeat;
    //background-size: 100% 100%;
    height: 80%;
    margin: 0.2rem 0.3rem;

    .video_div {
      width: 100%;
      height: 100%;
    }

    .cardvideo {
      background: #fff;
      height: 2.4rem;
      width: 2rem;
      position: relative;
      left: 0.6rem;
      top: 0.6rem;

      .date {
        font-size: 0.18rem;
        margin-left: 0.2rem;
      }

      .content {
        font-size: 0.08rem;
        width: 75%;
        margin: 0.2rem;
        line-height: 0.2rem;
      }

      .more {
        font-size: 0.06rem;
        color: #0087ff;
      }
    }
  }
}
</style>
<!-- <style lang="scss">
.fp-scroller {
  height: 100%;
}
</style> -->
