<template>
  <div class="about">
    <div class="section">
      <Section1></Section1>
      <ScrollTip></ScrollTip>
    </div>
    <div class="section">
      <Section2></Section2>
      <Section5></Section5>
      <Section4></Section4>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import ScrollTip from "../ScrollTip";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Footer from "../Footer";

export default {
  components: {
    ScrollTip,
    Section1,
    Section2,
    Section4,
    Section5,
    Footer
  },
  mounted() {
    const ua = navigator.userAgent;
    const ipad = ua.match(/(iPad).*OS\s([\d_]+)/);
    const isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/);
    const isAndroid = ua.match(/(Android)\s+([\d.]+)/);
    const isMobile = isIphone || isAndroid;
    if (!isMobile) {
      this._fullPage = new window.fullpage(this.$el, {
        anchors: ["section1"],
        scrollOverflow: true,
        scrollingSpeed: 700,
        // afterLoad: function(anchorLink, index) {
        //   // 回调函数内容
        //   // console.log("页面加载完成: 第" + index + "页");
        // }
      });
    }
  },
  beforeDestroy() {
    if (this._fullPage) {
      this._fullPage.destroy("all");
    }
  }
};
</script>

<style lang="scss" scoped>
.section {
  position: relative;
}
</style>
