<template>
  <div class="solution">
    <div class="section">
      <Header></Header>
      <Section1 ref="solution1"></Section1>
      <ScrollTip></ScrollTip>
    </div>
    <div class="section">
      <Section2 ref="solution2"></Section2>
      <ScrollTip></ScrollTip>
    </div>
    <div class="section">
      <Section3 ref="solution3"></Section3>
      <ScrollTip></ScrollTip>
    </div>
    <div class="section">
      <Section4 ref="solution4"></Section4>
      <ScrollTip></ScrollTip>
    </div>
    <div class="section">
      <Section5 ref="solution5"></Section5>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import ScrollTip from "../ScrollTip";
import Header from "../Header";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Footer from "../Footer";

export default {
  components: {
    ScrollTip,
    Header,
    Section1,
    Section2,
    Section3,
    Section4,
    Section5,
    Footer
  },
  mounted() {
    const ua = navigator.userAgent;
    const ipad = ua.match(/(iPad).*OS\s([\d_]+)/);
    const isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/);
    const isAndroid = ua.match(/(Android)\s+([\d.]+)/);
    const isMobile = isIphone || isAndroid;
    let vm = this;
    if (!isMobile) {
      this._fullPage = new window.fullpage(this.$el, {
        anchors: ["section1", "section2", "section3", "section4", "section5"],
        scrollOverflow: true,
        scrollingSpeed: 700,
        afterLoad: function(preAnchor, curAnchor) {
          if (curAnchor.index == 0) {
            vm.$refs.solution1.goAnimation();
          } else if (curAnchor.index == 1) {
            vm.$refs.solution2.goAnimation();
          } else if (curAnchor.index == 2) {
            vm.$refs.solution3.goAnimation();
          } else if (curAnchor.index == 3) {
            vm.$refs.solution4.goAnimation();
          } else if (curAnchor.index == 4) {
            vm.$refs.solution5.goAnimation();
          }
        },
        onLeave: function(leaveAnchor) {
          if (leaveAnchor.index == 0) {
            vm.$refs.solution1.removeAnimation();
          } else if (leaveAnchor.index == 1) {
            vm.$refs.solution2.removeAnimation();
          } else if (leaveAnchor.index == 2) {
            vm.$refs.solution3.removeAnimation();
          } else if (leaveAnchor.index == 3) {
            vm.$refs.solution4.removeAnimation();
          } else if (leaveAnchor.index == 4) {
            vm.$refs.solution5.removeAnimation();
          }
        }
      });
    }
  },
  beforeDestroy() {
    if (this._fullPage) {
      this._fullPage.destroy("all");
    }
  }
};
</script>

<style lang="scss" scoped>
// .solution {
//   position: relative;
// }
.section {
  position: relative;
}
</style>
