<template>
  <div class="section3">
    <div class="arrows">
      <img
        class="arrow-item arrow-item-prev"
        src="./imgs/arrow.png"
        @click="prev"
      />
      <img class="arrow-item" src="./imgs/arrow.png" @click="next" />
    </div>
    <div class="swipper">
      <transition name="fade">
        <div class="swipper-item" v-if="currentIndex == 0">
          <div class="swipper-item-img swipper-item1">
            <span class="swipper-item-title">我们的专长</span>
            <span class="swipper-item-subtitle">Our expertise</span>
          </div>
          <div class="swipper-item-img-next swipper-item2">
            <span class="swipper-item-title-right">成就您所需的液体</span>
            <span class="swipper-item-subtitle-right"
              >Get the liquid you need</span
            >
          </div>
          <div class="swipper-item-div">
            <div class="swipper-item-text">
              TDS是一项用来评估水质的重要指标之一，TDS值越高，就表示水中含有的杂质越多，水中的多种杂质会产生不同类型的水垢。
            </div>
            <div class="swipper-item-num-text">{{ this.numCountStr }} / 03</div>
          </div>
        </div>
      </transition>
      <transition name="fade">
        <div class="swipper-item" v-if="currentIndex == 1">
          <div class="swipper-item-img swipper-item2">
            <span class="swipper-item-title">成就您所需的液体</span>
            <span class="swipper-item-subtitle">Get the liquid you need</span>
          </div>
          <div class="swipper-item-img-next swipper-item3">
            <span class="swipper-item-title-right">成就您所需的液体</span>
            <span class="swipper-item-subtitle-right"
              >Get the liquid you need</span
            >
          </div>
          <div class="swipper-item-div">
            <div class="swipper-item-text">
              TDS是一项用来评估水质的重要指标之一，TDS值越高，就表示水中含有的杂质越多，水中的多种杂质会产生不同类型的水垢。
            </div>
            <div class="swipper-item-num-text">{{ this.numCountStr }} / 03</div>
          </div>
        </div>
      </transition>
      <transition name="fade">
        <div class="swipper-item" v-if="currentIndex == 2">
          <div class="swipper-item-img swipper-item3">
            <span class="swipper-item-title">成就您所需的液体</span>
            <span class="swipper-item-subtitle">Get the liquid you need</span>
          </div>
          <div class="swipper-item-img-next swipper-item1">
            <span class="swipper-item-title-right">我们的专长</span>
            <span class="swipper-item-subtitle-right">Our expertise</span>
          </div>
          <div class="swipper-item-div">
            <div class="swipper-item-text">
              TDS是一项用来评估水质的重要指标之一，TDS值越高，就表示水中含有的杂质越多，水中的多种杂质会产生不同类型的水垢。
            </div>
            <div class="swipper-item-num-text">{{ this.numCountStr }} / 03</div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentIndex: 0,
      numCount: 1,
      numCountStr: "01"
    };
  },
  watch: {
    currentIndex() {
      if (this._ind) {
        clearTimeout(this._ind);
        this._ind = setTimeout(this.next, 5000);
      }
    }
  },
  methods: {
    next() {
      this.currentIndex++;
      this.numCount++;
      if (this.currentIndex > 2) {
        this.currentIndex = 0;
        this.numCount = 1;
      }
      this.numCountStr = "0" + this.numCount;
    },
    prev() {
      this.currentIndex--;
      this.numCount--;
      if (this.currentIndex < 0) {
        this.currentIndex = 2;
        this.numCount = 3;
      }
      this.numCountStr = "0" + this.numCount;
    }
  },
  created() {
    // this._ind = setTimeout(this.next, 5000);
  },
  beforeDestroy() {
    if (this._ind) {
      clearTimeout(this._ind);
    }
  }
};
</script>

<style lang="scss" scoped>
.section3 {
  position: relative;
  height: 4.4rem;
  padding: 1px 0;
  .arrows {
    position: absolute;
    z-index: 2;
    left: 5.35rem;
    top: 2.94rem;
    .arrow-item {
      display: inline-block;
      vertical-align: middle;
      width: 0.2rem;
      margin-right: 0.1rem;
      opacity: 0.7;
      transition: all 0.2s;
      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    }
    .arrow-item-prev {
      transform: rotate(180deg);
      padding-left: 0.2rem;
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .swipper {
    position: relative;
    height: 4.4rem;
    .swipper-item {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 4.4rem;
    }

    .swipper-item1 {
      background: url(./imgs/section3-01.png) no-repeat;
      background-size: 100% 100%;
    }
    .swipper-item2 {
      background: url(./imgs/section3-02.png) no-repeat;
      background-size: 100% 100%;
    }
    .swipper-item3 {
      background: url(./imgs/section3-03.png) no-repeat;
      background-size: 100% 100%;
    }

    .swipper-item-title {
      font-size: 0.14rem;
      position: absolute;
      left: 30%;
      color: #fff;
      top: 0.3rem;
    }

    .swipper-item-subtitle {
      font-size: 0.08rem;
      position: absolute;
      left: 30%;
      color: #fff;
      top: 0.9rem;
    }

    .swipper-item-title-right {
      font-size: 0.14rem;
      position: absolute;
      left: 10%;
      color: #fff;
      top: 0.3rem;
    }

    .swipper-item-subtitle-right {
      font-size: 0.08rem;
      position: absolute;
      left: 10%;
      color: #fff;
      top: 0.9rem;
    }

    .swipper-item-div {
      position: absolute;
      // z-index: 11212213;
      background-image: url("./imgs/bottom_img.png");
      background-size: 100% 100%; /* 背景图片覆盖整个div区域 */
      // background-position: center;                    /* 背景图片居中 */
      // height: 0.4rem;
      // font-size: 0.07rem;
      // padding: 0.5rem 1rem;
      width: 100%;
      top: 3.39rem;
      // height: 1.56rem;
    }

    .swipper-item-text {
      float: left;
      width: 40%;
      height: 0.7rem;
      font-size: 0.08rem;
      margin-left: 10%;
      margin-top: 0.55rem;
    }

    .swipper-item-num-text {
      float: right;
      width: 20%;
      font-size: 0.12rem;
      margin-top: 0.55rem;
    }

    .swipper-item-img {
      height: 3.6rem;
      position: absolute;
      width: 60%;
    }

    .swipper-item-img-next {
      height: 3.6rem;
      position: absolute;
      width: 40%;
      left: 60%;
    }

    .title {
      font-size: 0.34rem;
      text-align: center;
      margin-top: 1rem;
    }
    .items {
      font-size: 0;
      text-align: center;
      margin-top: 0.3rem;
      .item {
        display: inline-block;
        font-size: 0.15rem;
        line-height: 0.15rem;
        vertical-align: middle;
        margin-right: 0.1rem;
        padding-right: 0.1rem;
        border-right: 1px solid #fff;
        &:last-child {
          margin-right: 0;
          border: none;
        }
      }
    }
  }
}
</style>
