<template>
  <div class="section1">
    <div class="banner">
      <img id="section1Content" src="./imgs/section1-text-1.png" />
    </div>
    <!-- <div id="section1Title" class="title-ani">关于U2</div>
    <div id="section1Content" class="content-ani">
      <div>u2的故事始于对液体科学的深刻理解</div>
      <div>我们的创始人发现了液体在不同环境下的清洁潜力</div>
      <div>他一直致力于生产卓越、优质的产品，只为将这些专业级的液体清洁技术带给到每个家庭。</div>
      <p></p>
      <div>几十年来，U2以精湛的技术和创新的理念，成为了清洁产品领域的佼佼者，丰富的产品几乎涵盖了日常生活的方方面面，</div>
      <div>让更多的家庭体验到了U2带来的品质生活。</div>
      <p></p>
      <div>无论是在航空业、艺术殿堂还是奢侈品牌领域，U2都以专业的产品和服务赢得了广泛的认可。</div>
      <div>空客、凡尔赛宫、卢浮宫等，都选择了U2作为他们的合作伙伴。</div>
      <div>无论是在家中还是工作场所，U2都能为您带来清新和舒适。</div>
      <div>如今，U2的产品已经出口到全球多个国家和地区，受到了当地消费者的喜爱。</div>
      <div>未来，U2将继续探索液体的无限可能，为大家带来更多惊喜。</div>
    </div> -->
  </div>
</template>

<style lang="scss" scoped>
.section1 {
  position: relative;
  height: 5.6rem;
  background: #fff;
  .banner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5.6rem;
    background: url(./imgs/section1-bg-1.png) no-repeat;
    background-size: 100% 100%;

    img {
      position: absolute;
      left: 8%;
      top: 8%;
    }
  }

  .title-ani {
    transform: translate(-50%, -50%); /* 使文字居中定位 */
    padding-top: 4%;
    font-size: 0.18rem; /* 文字大小 */
    width: 100%;
    color: #fff; /* 文字颜色 */
    text-align: center;
    opacity: 0;
  }

  .content-ani {
    // position: absolute; /* 文字的绝对定位 */
    transform: translate(-50%, -50%); /* 使文字居中定位 */
    padding-top: 2%;
    font-size: 0.08rem; /* 文字大小 */
    line-height: 0.2rem;
    width: 100%;
    color: #fff; /* 文字颜色 */
    text-align: center;
    opacity: 0;
  }

  .animationTitle {
    animation: move-text 2s forwards;
  }

  .animationContent {
    animation: move-text 2s forwards;
  }

  @keyframes move-text {
    0% {
      transform: translateY(-40px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
    to {
      opacity: 1;
    }
  }
}
</style>

<script>
export default {
  created() {
    // console.log("onCreate");
    // window.addEventListener('scroll', this.onScrollFunc);
  },
  beforeDestroy() {
    // console.log("beforeDestroy");
    // window.removeEventListener('scroll', this.onScrollFunc);
  },
  methods: {
    goAnimation() {
      // var elementAni = document.getElementById('section1Title');
      // elementAni.classList.add('animationTitle');

      var elementAniText = document.getElementById("section1Content");
      elementAniText.classList.add("animationContent");
    },
    removeAnimation() {
      // var elementAni = document.getElementById('section1Title');
      // elementAni.classList.remove('animationTitle');

      var elementAniText = document.getElementById("section1Content");
      elementAniText.classList.remove("animationContent");
    }
  }
};
</script>
