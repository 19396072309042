<template>
  <div>
    <Header></Header>
    <div class="detail">
      <div class="simple-bg" v-if="currentIndex == 0">
        <!-- <img class="simple-img-1" src="./imgs/detail-bg.png" /> -->
        <div class="si-title-1">全球高端清洁洗护品牌U2</div>
        <div class="si-title-2">宣布进军中国市场</div>
        <div class="content-1">
          近日，全球知名的清洁品牌U2宣布正式进入中国市场。在全球经济面临挑战和不确定性的背景下，这一举动展现了U2对中国市场的坚定信心。进军中国市场的决策不仅是U2品牌在全球战略中的关键一步，也是对中国经济持续增长潜力的坚定投资。
        </div>
        <div class="content-2">
          据悉，U2品牌在全球市场享有盛誉，以其创新技术和优质产品而闻名。该品牌的产品广泛应用于航空、奢侈品、历史文化遗址等多个领域，展现了其在行业中的领导地位和多元化实力。U2致力于通过持续的技术创新和卓越的客户服务，为全球客户提供高标准的清洁解决方案。
        </div>
        <div class="content-2">
          官方消息显示，U2在商业市场上取得了显著的成就，成为包括法国空客公司、国际卫生用品品牌BUNZL、汽车摩托车领域品牌Ipone、兽医领域品牌beaphar贝帮、专业的个人护理用品生产商essity等全球知名企业的指定供应品牌，同时也是法国凡尔赛宫等历史名胜的长期合作伙伴。这些高端合作经验使U2在国际市场上建立了无与伦比的信誉和品牌价值。
        </div>
        <div class="content-2">
          据本报记者了解，面对中国这个充满活力和机遇的市场，U2品牌计划将融合其多年服务国际顶级品牌所积累的丰富产品与技术优势，在中国市场，围绕家庭洗护场景，针对家居的清洁领域，根据中国各地域独特的水质情况推出创新性改良产品。U2品牌致力于根据不同家庭的需求提供定制化解决方案，以迎合中国消费者对高品质生活的追求。
        </div>
        <div class="content-2">
          据本报记者采访，在全球经济的波动中，U2品牌选择进入中国市场是出于对中国长远发展的信心。U2市场负责人向本报记者表示：“我们对中国市场的潜力充满信心。凭借U2的技术创新和品牌实力，我们相信能够在这片充满活力的土地上取得显著成就。”
        </div>
        <div class="content-2">
          最新动态显示，随着业务的不断扩展，U2品牌一直致力于开拓新的市场，并积极响应各地区的文化和环境特性，以提供更加贴合当地需求的产品。特别是在中国市场，U2品牌计划加强布局，深化与中国合作伙伴的关系，并通过本土化的产品研发和市场策略，更好地服务于中国消费者。
        </div>
        <div class="content-2">
          展望未来，U2品牌将继续坚持其核心价值观，通过技术创新和差异化的产品开发，为中国市场提供卓越的产品体验，促进中国市场的繁荣与发展。U2的这一决策不仅是对中国市场无限潜力的肯定，也是中外经济合作新时代的明证。
        </div>
        <p></p>
      </div>

      <div class="complex-bg" v-if="currentIndex == 1">
        <div class="title-1">百年底蕴焕发新活力，</div>
        <div class="title-2">法国品牌U2将改变中国高端清洗剂市场！</div>

        <div class="complex-content-1">
          研发历史跨过3/4个世纪，在产品面世的第40个年头，U2品牌正式宣布进入中国民用清洁领域。
        </div>
        <div class="complex-content-2">
          高端电器产品的清洁一直是个难题，内部结构精密复杂，给日常清洁带来不小的困难。而U2的高端清洁洗护将提交一份满意的答案。
        </div>

        <div class="div-img-1">
          <img class="img-1" src="./imgs/detail-1.png" />
        </div>

        <div class="complex-content-2">
          U2始于1936年的米歇尔实验室，当时实验室的研究方向正是研究杀虫剂以及清洁类产品，并在1949年正式成立为专门研制气雾剂和液体产品研发生产公司。
        </div>
        <div class="complex-content-2">
          经过多年的生产及研发积累，1984年，面向民用清洁领域的品牌U2终于诞生了。
        </div>

        <div class="div-img-1">
          <img class="img-1" src="./imgs/detail-2.png" />
        </div>

        <div class="complex-content-2">
          U2是兼具双重专业标准的缩写，高品质的清洁能力与高级香氛结合，给用户双重高级的使用体验。清新愉悦的味道结合舒适的使用体验，让用户度过美好的一天。
        </div>
        <div class="complex-content-2">
          随着研发与技术的进步，U2在法国高端商用清洗市场崭露头角，陆续成为凡尔赛宫、卢浮宫等重要场所的合作清洁品牌。
        </div>
        <div class="complex-content-2">
          随着航空业的发展，U2也针对空客飞机的使用场景，设计并研发了清洁类产品。
        </div>
        <div class="complex-content-2">
          这一切也是得益于U2强大的研发及生产团队，前沿的资深研发人员全权负责监管从研发到生产的每一步，专业的事情让专业的人来做；四大先进生产基地和丰富的生产线，更是为品牌打下了坚实的基础。
        </div>

        <div class="div-img-1">
          <img class="img-1" src="./imgs/detail-3.png" />
        </div>

        <div class="complex-content-2">
          U2与中国的渊源始于2017年，当时它被选为中国天津空客的供应商。
        </div>
        <div class="complex-content-2">
          针对高端产品的洗护，至今都没能有一个家喻户晓的品牌杀出重围，U2团队紧紧抓住机遇，在2024年正式进军中国高端洗护市场。
        </div>
        <div class="complex-content-2">
          针对中国“不同水质”采用“不同配方”的研发方案，把选择权重新交回用户手上，锁定自己家的TDS值（溶解性固体总量）范围，就能选择适合的洗衣机清洗剂。
        </div>
        <div class="complex-content-2">因地制宜，给清洁效果提供优解。</div>
        <div class="complex-content-2">
          经过科学严谨的测试及使用反馈，真正能做到除菌除病毒的同时，保护内部零件，保护洗衣机使用寿命。
        </div>
        <div class="complex-content-2">
          同时无需浸泡预洗等便利的使用方法，给消费者带来更舒适的清洗体验。
        </div>
        <div class="complex-content-2">
          U2品牌在未来将融合多年服务国际品牌所积累的产品与技术优势，结合中国本土市场的发展特点及环境特性，围绕家庭洗护场景，持续为中国用户带来高水准的产品，更好地满足用户对高品质生活的追求。
        </div>
        <p></p>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../Footer";
import Header from "../Header";

export default {
  components: {
    Header,
    Footer
  },
  created() {
    this.currentIndex = this.$route.params.id;
    //console.log('User ID:', this.currentIndex);
  },
  data() {
    return {
      currentIndex: 0
    };
  }
};
</script>

<style lang="scss" scoped>
.detail {
  position: relative;
  width: 100%;
  // height: 17rem;
  background: #fff;
}

.simple-bg {
  .si-title-1 {
    font-size: 0.18rem;
    margin-right: 2rem;
    margin-left: 2rem;
    margin-top: 0.25rem;
    text-align: center;
  }

  .si-title-2 {
    font-size: 0.18rem;
    margin-right: 2rem;
    margin-left: 2rem;
    margin-top: 0.15rem;
    text-align: center;
  }

  .simple-img-1 {
    width: 100%;
  }

  .content-1 {
    text-indent: 0.26rem;
    font-size: 0.12rem;
    line-height: 0.25rem;
    margin-right: 2.5rem;
    margin-left: 2.5rem;
    margin-top: 0.4rem;
  }

  .content-2 {
    text-indent: 0.26rem;
    font-size: 0.12rem;
    line-height: 0.25rem;
    margin-right: 2.5rem;
    margin-left: 2.5rem;
    margin-top: 0.2rem;
  }
}

.complex-bg {
  .title-1 {
    font-size: 0.18rem;
    margin-right: 2rem;
    margin-left: 2rem;
    margin-top: 0.25rem;
    text-align: center;
  }

  .title-2 {
    font-size: 0.18rem;
    margin-right: 2rem;
    margin-left: 2rem;
    margin-top: 0.15rem;
    text-align: center;
  }

  .complex-content-1 {
    text-indent: 0.2rem;
    font-size: 0.1rem;
    line-height: 0.2rem;
    margin-right: 3rem;
    margin-left: 3rem;
    margin-top: 0.5rem;
  }

  .complex-content-2 {
    text-indent: 0.2rem;
    font-size: 0.1rem;
    line-height: 0.2rem;
    margin-right: 3rem;
    margin-left: 3rem;
    margin-top: 0.2rem;
  }

  .div-img-1 {
    display: flex;
    justify-content: center;
    margin-top: 0.2rem;
  }

  .img-1 {
    width: 37%;
  }
}
</style>
