<template>
  <div class="section5">
    <div class="banner">
      <img id="toAnimate_img" src="./imgs/lishi.png" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.section5 {
  position: relative;
  height: 5.8rem;
  background: #fff;
  .banner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5.8rem;
    background: url(./imgs/section5-bg-1.png) no-repeat;
    background-size: 100% 100%;

    img {
      width: 60%;
      left: 20%;
      top: 10%;
      position: absolute;
      opacity: 0;
    }
  }

  .animateImg {
    animation: move-text 2s forwards;
  }

  @keyframes move-text {
    0% {
      transform: translateY(-40px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
    to {
      opacity: 1;
    }
  }
}
</style>

<script>
export default {
  created() {},
  beforeDestroy() {},
  methods: {
    goAnimation() {
      var elementAni = document.getElementById("toAnimate_img");
      elementAni.classList.add("animateImg");
    },
    removeAnimation() {
      var elementAni = document.getElementById("toAnimate_img");
      elementAni.classList.remove("animateImg");
    }
  }
};
</script>
