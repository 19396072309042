<template>
  <div class="header" :class="{ 'header-absolute': absolute }">
    <img class="logo" src="../assets/logo.png" />
    <div class="nav">
      <div
        class="link"
        :class="{ 'link-active': $route.path == link.path }"
        v-for="link in links"
        :key="link.path"
        @click="go(link.path)"
      >
        {{ link.name }}
        <div v-if="link.children" class="submenu-box">
          <div
            class="submenu-item"
            v-for="(sub, ind) in link.children"
            :key="'sub-' + ind"
            @click.stop="go(sub.path)"
          >
            {{ sub.name }}
          </div>
        </div>
      </div>
    </div>
    <img class="buy" src="../assets/buy.png" @click="goBuy" />
  </div>
</template>

<script>
export default {
  props: ["absolute"],
  data() {
    return {
      links: [
        {
          path: "/",
          name: "首页"
        },
        { path: "/case", name: "产品展示" },
        {
          path: "/solution",
          name: "品牌介绍"
        },
        { path: "/about", name: "联系我们" }
      ]
    };
  },
  methods: {
    go(path) {
      this.$router.push(path);
    },
    goBuy() {
      window.open("https://u2xihu.tmall.com");
    }
  }
};
</script>

<style lang="scss">
.header {
  position: relative;
  z-index: 19881111;
  width: 100%;
  padding: 0.285rem 0;
  height: 0.16rem;
  line-height: 0.24rem;
  color: #fff;
  .logo {
    float: left;
    height: 0.24rem;
    width: 0.24rem;
    margin-left: 1.5rem;
  }
  .buy {
    margin-left: 1rem;
    cursor: pointer;
  }
  .nav {
    float: left;
    margin-left: 0.4rem;
    .link {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      height: 0.24rem;
      line-height: 0.24rem;
      color: #999;
      text-decoration: none;
      transition: color 0.3s;
      font-size: 0.09rem;
      margin-left: 0.8rem;
      outline: none;
      cursor: pointer;
      &:hover {
        color: #dfb25f;
        .submenu-box {
          transform: scale(1, 1);
        }
      }
    }
    .link-active {
      color: #dfb25f;
      border-bottom: 1px solid #dfb25f;
    }
  }
}
.header-absolute {
  position: absolute;
  z-index: 19881111;
  top: 0;
  left: 0;
  right: 0;
}
.submenu-box {
  position: absolute;
  top: 0.24rem;
  left: -0.1rem;
  width: auto;
  background: #000;
  transition: all 0.3s;
  transform: scale(1, 0);
  transform-origin: left top;
  .submenu-item {
    height: 0.24rem;
    line-height: 0.24rem;
    padding: 0 0.1rem;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      color: #fff;
    }
  }
}
</style>
