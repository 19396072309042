<template>
  <div style="height: 100%;">
    <div class="section3">
      <img id="section3Title" class="title" src="./imgs/section3-title.png" />
      <img id="section3List" class="list" src="./imgs/section3-bg.png" />
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../Footer";

export default {
  components: {
    Footer
  },

  created() {
    window.addEventListener("scroll", this.onScrollFunc);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScrollFunc);
  },
  methods: {
    goAnimation() {
      var elementAni = document.getElementById("section3Title");
      elementAni.classList.add("title");
      elementAni.classList.add("animatetitle");

      var elementAniText = document.getElementById("section3List");
      elementAniText.classList.add("list");
      elementAniText.classList.add("animatelist");
    },
    removeAnimation() {
      var elementAni = document.getElementById("section3Title");
      elementAni.classList.remove("animatetitle");

      var elementAniText = document.getElementById("section3List");
      elementAniText.classList.remove("animatelist");
    }
  }
};
</script>

<style lang="scss" scoped>
.section3 {
  height: 5.4rem;
  background: #f9f9fd;
  background-size: 100% 100%;
  .title {
    margin: 0.4rem 0.3rem 0.3rem 3.5rem;
    font-size: 0.18rem;
    height: 0.6rem;
    opacity: 0;
  }

  .list {
    width: 100%;
    opacity: 0;
  }

  @keyframes move-text {
    0% {
      transform: translateY(0);
      opacity: 0;
    }
    100% {
      transform: translateY(-40px);
      opacity: 1;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes move-text2 {
    0% {
      transform: translateY(0);
      opacity: 0;
    }
    100% {
      transform: translateY(-80px);
      opacity: 1;
    }
    to {
      opacity: 1;
    }
  }
}

.animatetitle {
  animation: move-text 0.5s forwards;
}

.animatelist {
  animation: move-text2 1s forwards;
}
</style>
