<template>
  <div class="section2">
    <div class="banner"></div>
    <div id="section2Title" class="title-ani">品牌愿景</div>
    <div id="section2Desc" class="desc-ani">用专业知识</div>
    <div id="section2Desc2" class="desc-ani2">为客户带来更美好的生活体验</div>
  </div>
</template>

<style lang="scss" scoped>
.section2 {
  position: relative;
  height: 5.8rem;
  background: #fff;
  text-align: center; /* 文字居中 */

  .banner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5.8rem;
    background: url(./imgs/section2-bg-1.png) no-repeat;
    background-size: 100% 100%;
  }

  .title-ani {
    position: absolute; /* 文字的绝对定位 */
    transform: translate(-50%, -50%); /* 使文字居中定位 */
    top: 36%; /* 放置在容器的垂直中间 */
    left: 45%; /* 放置在容器的水平中间 */
    font-size: 0.14rem; /* 文字大小 */
    width: 9%;
    color: white; /* 文字颜色 */
    border: 1px solid #fff;
    border-radius: 20px;
    text-shadow: 1px 3px 5px #666;
    opacity: 0;
  }

  .desc-ani {
    text-shadow: 1px 3px 5px #666;
    position: absolute; /* 文字的绝对定位 */
    transform: translate(-50%, -50%); /* 使文字居中定位 */
    top: 45%; /* 放置在容器的垂直中间 */
    left: 33%; /* 放置在容器的水平中间 */
    font-size: 0.3rem; /* 文字大小 */
    width: 33%;
    color: white; /* 文字颜色 */
    opacity: 0;
  }

  .desc-ani2 {
    text-shadow: 1px 3px 5px #666;
    position: absolute; /* 文字的绝对定位 */
    transform: translate(-50%, -50%); /* 使文字居中定位 */
    top: 55%; /* 放置在容器的垂直中间 */
    left: 28.5%; /* 放置在容器的水平中间 */
    font-size: 0.3rem; /* 文字大小 */
    width: 42%;
    color: white; /* 文字颜色 */
    opacity: 0;
  }

  .animationTitle {
    animation: move-text 0.8s forwards;
  }

  .animationDesc {
    animation: move-text 2.5s forwards;
  }

  @keyframes move-text {
    0% {
      transform: translateY(0);
      opacity: 0;
    }
    100% {
      transform: translateY(-40px);
      opacity: 1;
    }
    to {
      opacity: 1;
    }
  }
}
</style>

<script>
export default {
  created() {
    // console.log("onCreate");
    // window.addEventListener('scroll', this.onScrollFunc);
  },
  beforeDestroy() {
    // console.log("beforeDestroy");
    // window.removeEventListener('scroll', this.onScrollFunc);
  },
  methods: {
    goAnimation() {
      var elementAni = document.getElementById("section2Title");
      elementAni.classList.add("animationTitle");

      var elementAniText = document.getElementById("section2Desc");
      elementAniText.classList.add("animationDesc");

      var elementAniText2 = document.getElementById("section2Desc2");
      elementAniText2.classList.add("animationDesc");
    },
    removeAnimation() {
      var elementAni = document.getElementById("section2Title");
      elementAni.classList.remove("animationTitle");

      var elementAniText = document.getElementById("section2Desc");
      elementAniText.classList.remove("animationDesc");

      var elementAniText2 = document.getElementById("section2Desc2");
      elementAniText2.classList.remove("animationDesc");
    }
  }
};
</script>
