<template>
  <div class="section4">
    <div class="prdlist">
      <div class="item" id="prdlist">
        <div class="card" v-for="(item, index) in showprdlist" :key="index">
          <img :src="item.img" class="prdimg" />
        </div>
      </div>
    </div>
    <!-- <el-carousel :interval="4000" type="card" height="80%" class="prdList">
      <el-carousel-item v-for="item in prdimgList" :key="item.id">
        <img :src="item.img" class="prditemImg" />
      </el-carousel-item>
    </el-carousel> -->
    <!-- <div class="cells">
      <div class="cell cell1">
        <div class="cell-title">
          布莱斯工厂
        </div>
      </div>
      <div class="cell cell2">
         <div class="cell-title">
          莫朗工厂
        </div> 
      </div>
      <div class="cell cell3">
        <div class="cell-title">
          圣埃格雷夫工厂
        </div> 
      </div>
      <div class="cell cell4">
         <div class="cell-title">
          沃雷普工厂
        </div> 
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      prdimgList: [
        { id: 0, img: require("./imgs/prdimg-1.png") },
        { id: 1, img: require("./imgs/prdimg-2.png") },
        { id: 2, img: require("./imgs/prdimg-3.png") },
        { id: 3, img: require("./imgs/prdimg-4.png") }
      ],
      showprdlist: []
    };
  },
  created() {
    for (let i = 0; i < 1000; i++) {
      this.showprdlist = this.showprdlist.concat(this.prdimgList);
    }
  }
};
</script>

<style lang="scss">
.el-carousel__mask {
  background-color: transparent !important;
}
</style>

<style lang="scss" scoped>
.section4 {
  position: relative;
  height: 2.4rem;
  background: url("./imgs/section3-bg.png") no-repeat;
  background-size: 100% 100%;
  padding: 1px 0;
  // .prdList {
  //   width: 70%;
  //   margin-left: 15%;
  //   height: 2.4rem;
  //   margin-top: 0.2rem;
  //   .prditemImg {
  //     width: 100%;
  //     height: 100%;
  //     object-fit: scale-down;
  //   }
  // }
  .prdlist {
    width: 60%;
    margin-left: 20%;
    overflow: hidden;

    .item {
      // margin: 0 1rem;
      // display: flex;
      // flex-direction: row;
      // flex-wrap: nowrap;
      // align-content: center;
      // justify-content: center;
      // align-items: center;
      display: inline-block;
      white-space: nowrap;
      animation: scrollLeft 55000s linear infinite;

      .card {
        // background: #f7f7f7;
        width: 2.2rem;
        height: 2rem;
        position: relative;
        font-size: 0.07rem;
        // margin-left: 2.5%;
        margin-top: 0.2rem;
        display: inline-block;
        margin-right: 0.2rem;

        .prdimg {
          width: 100%;
          position: absolute;
        }

        .prdimg3 {
          width: 100%;
          margin-top: 0.4rem;
          position: absolute;
        }

        div {
          bottom: 0;
          left: 0.1rem;
          right: 0.1rem;
          position: absolute;
          height: 0.2rem;
          line-height: 0.2rem;
          width: 86%;

          .arrowright {
            width: 0.2rem;
            float: right;
            margin-top: 0.08rem;
          }
        }
      }
    }
  }
  .cells {
    margin: 0.615rem 1.4rem;
    font-size: 0;
    .cell {
      display: inline-block;
      vertical-align: middle;
      width: 2.8rem;
      height: 1.3rem;
      margin-top: 0.43rem;
      margin-right: 0.43rem;
      border-radius: 3px;
      transition: all 0.3s;
      &:last-child {
        margin-right: 0;
      }
    }

    .cell1 {
      background: url("./imgs/section3-bg-1.png") no-repeat;
      background-size: 100% 100%;
    }

    .cell2 {
      background: url("./imgs/section3-bg-2.png") no-repeat;
      background-size: 100% 100%;
    }

    .cell3 {
      background: url("./imgs/section3-bg-3.png") no-repeat;
      background-size: 100% 100%;
    }

    .cell4 {
      background: url("./imgs/section3-bg-4.png") no-repeat;
      background-size: 100% 100%;
    }

    .cell-title {
      margin-top: 1.36rem;
      font-size: 0.14rem;
      line-height: 0.17rem;
      color: #fff;
      text-align: center;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      img {
        height: 0.1rem;
        margin-right: 0.05rem;
      }
    }
    .cell-txt {
      margin: 0.49rem 0.3rem;
      font-size: 0.11rem;
      color: #171717;
      text-align: center;
      line-height: 0.2rem;
    }
  }

  .imgdiv {
    width: 100%;
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    height: 6rem;

    .imgdutu {
      width: 90%;
    }
  }

  /* 定义动画 */
  @keyframes scrollLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(
        calc(-100% - 10px)
      ); /* 加上一些额外的空白，以便滚动到最后一个元素时不会立即切换 */
    }
  }
}
</style>
