<template>
  <div class="section2">
    <div class="title1">
      联系我们
      <p>杭州总部</p>
      <span>杭州总部：由图（杭州）品牌管理有限公司</span>
      <br />
      <span>注册地址：浙江省杭州市上城区鼎辉城2幢1111室</span>
      <br />
      <span>联系邮箱：U2_HANGZHOU@163.COM</span>
    </div>
    <img src="./imgs/section2-bg-1.png" class="year" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.section2 {
  position: relative;
  height: 3rem;
  background: #fff;
  .title1 {
    position: absolute;
    top: 0.775rem;
    left: 1.23rem;
    font-size: 0.22rem;
    line-height: 0.34rem;
    color: #dfb25f;
    p {
      color: #000;
      font-weight: bold;
      font-size: 0.15rem;
      line-height: 0.08rem;
    }

    span {
      color: #212121;
      font-size: 0.12rem;
      line-height: 0.08rem;
      height: 0.12rem;
    }
  }
  .year {
    position: absolute;
    top: 0.98rem;
    left: 4.78rem;
    height: 1.5rem;
  }
  .article1 {
    position: absolute;
    top: 2.32rem;
    left: 0.95rem;
    right: 0.95rem;
  }
  .article2 {
    position: absolute;
    top: 3.685rem;
    left: 0.95rem;
    right: 0.95rem;
  }
  .title {
    font-size: 0.18rem;
    color: #000;
    margin-bottom: 0.3rem;
  }
  .txt {
    font-size: 0.13rem;
    color: #000;
    line-height: 0.3rem;
  }
}
</style>
