<template>
  <div class="section4">
    <div class="title">
      <div class="title1">
        <span class="sub">LATEST NEWS</span>
        <span>最新资讯</span>
      </div>
      <div class="title2">
        <span>快来看看U2</span>
        <span>最近又发生了什么吧！</span>
      </div>
    </div>

    <img class="imgleft" src="./imgs/left.png" @click="prev" />
    <img class="imgright" src="./imgs/right.png" @click="next" />

    <div class="newsbody">
      <el-carousel
        class="leftBtnNone"
        height="5.2rem"
        :autoplay="false"
        :indicator-position="none"
        arrow="never"
        ref="carousel"
      >
        <el-carousel-item v-for="(item, index) in infoList" :key="index">
          <div
            class="news"
            :style="{ backgroundImage: 'url(' + item.img + ')' }"
          >
            <div class="card" @click="gotoDetail(index)">
              <span class="date">{{ item.date }}</span>
              <span class="content">{{ item.text }}</span>
              <!-- <span class="more">阅读更多</span> -->
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      currentIndex: 0,
      infoList: [
        {
          date: "03/12-2024",
          text:
            "研发历史跨过3/4个世纪，在产品面世的第40个年头，U2品牌正式宣布进入中国民用清洁领域。高端电器产品的清洁一直是个难题，内部结构精密复杂，给日常清洁带来不小的困难。而U2的高端清洁洗护将提交一份满意的答案。",
          img: require("./imgs/section5-bg.png")
        },
        {
          date: "03/12-2024",
          text:
            "近日，全球知名的清洁品牌U2宣布正式进入中国市场。在全球经济面临挑战和不确定性的背景下，这一举动展现了U2对中国市场的坚定信心。进军中国市场的决策不仅是U2品牌在全球战略中的关键一步，也是对中国经济持续增长潜力的坚定投资。",
          img: require("./imgs/section5-bg-2.png")
        }
      ]
    };
  },
  watch: {
    // currentIndex() {
    //   if (this._ind) {
    //     clearTimeout(this._ind);
    //     this._ind = setTimeout(this.next, 5000);
    //   }
    // }
  },
  methods: {
    next() {
      this.currentIndex++;
      if (this.currentIndex > 2) {
        this.currentIndex = 0;
      }
      this.$refs.carousel.next();
    },
    prev() {
      this.currentIndex--;
      if (this.currentIndex < 0) {
        this.currentIndex = 2;
      }
      this.$refs.carousel.prev();
    },
    gotoDetail(num) {
      //console.log("num  " + num);
      this.$router.push({ name: "details", params: { id: num } });
    }
  },
  created() {
    // this._ind = setTimeout(this.next, 5000);
  },
  beforeDestroy() {
    // if (this._ind) {
    //   clearTimeout(this._ind);
    // }
  }
};
</script>

<style lang="scss" scoped>
.section4 {
  height: 5.5rem;
  // height: 100%;
  position: relative;
  background: #f9f9fd;
  padding: 0 0.9rem;
  padding-top: 0.3rem;

  .title {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    .title1 {
      font-size: 0.15rem;
      display: grid;
      align-content: center;
      justify-content: center;
      align-items: center;
      justify-items: center;
      width: 1.3rem;
      border-right: 1px solid #212121;
      .sub {
        font-size: 0.08rem;
      }
    }
    .title2 {
      font-size: 0.08rem;
      display: grid;
      align-content: center;
      justify-content: center;
      align-items: center;
      justify-items: start;
      width: 1.3rem;
      line-height: 0.18rem;
    }
  }

  .fades4-enter-active,
  .fades4-leave-active {
    // transition: opacity 0.5s;
    transition: 1s linear;
  }
  .fades4-enter {
    transform: translateX(100%);
  }

  .fades4-leave-to {
    transform: translateX(-100%);
  }

  .news {
    position: absolute;
    width: 93%;
    // background: url("./imgs/section5-bg.png") no-repeat;
    background-size: 100% 100%;
    height: 4rem;
    // margin: 0.2rem 0.3rem;
    // animation: slide 2s forwards;
    bottom: 0.75rem;
    left: 0.3rem;
    right: 0.3rem;

    .card {
      cursor: pointer;
      background: #fff;
      height: 2.4rem;
      width: 2rem;
      position: relative;
      left: 0.6rem;
      top: 1rem;

      .date {
        font-size: 0.18rem;
        margin-left: 0.2rem;
      }

      .content {
        font-size: 0.08rem;
        width: 75%;
        margin: 0.2rem;
        line-height: 0.2rem;
      }

      .more {
        font-size: 0.06rem;
        color: #0087ff;
      }
    }
  }

  .imgleft {
    position: absolute;
    top: 30%;
    width: 0.45rem;
    z-index: 123132;
  }

  .imgright {
    position: absolute;
    top: 30%;
    width: 0.45rem;
    right: 9.5%;
    z-index: 123132;
  }

  @keyframes slide {
    0% {
      transform: translateX(180%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  .leftBtnNone {
    .el-carousel__arrow--left {
      display: none !important;
    }
  }

  .rightBtnNone {
    .el-carousel__arrow--right {
      display: none !important;
    }
  }
}
</style>
