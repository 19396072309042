<template>
  <div class="section2">
    <div class="prdimgs">
      <img
        class="prdimgitem"
        v-for="(item, index) in prdimgList"
        :key="index"
        :src="item.img"
        @click="selectimg(item.id, item.skuIndex)"
        :class="{ 'prdimgitem-checked': imgIndex == item.id }"
      />
    </div>
    <div class="prdtext">
      <span class="title">{{ item[this.imgIndex].title }}</span>
      <span class="label">{{ item[this.imgIndex].label }}</span>
      <span class="content">
        {{ item[this.imgIndex].content }}
      </span>
      <span class="empty" />
      <span class="content">{{ item[this.imgIndex].title1 }}</span>
      <span class="content">
        {{ item[this.imgIndex].content1 }}
      </span>
      <span class="empty" />
      <span class="content">{{ item[this.imgIndex].title2 }}</span>
      <span class="content">{{ item[this.imgIndex].content2 }}</span>
      <span class="empty" />
      <span class="content">{{ item[this.imgIndex].title3 }}</span>
      <span class="content">
        {{ item[this.imgIndex].content3 }}
      </span>
      <span class="empty" />
      <span class="content">{{ item[this.imgIndex].title4 }}</span>
      <span class="content">{{ item[this.imgIndex].content4 }}</span>
      <span class="empty" />
      <span class="tip">
        {{ item[this.imgIndex].tip }}
      </span>
      <span class="tip">
        {{ item[this.imgIndex].tip1 }}
      </span>
      <span class="empty" />
      <span class="tip">{{ item[this.imgIndex].tip2 }}</span>
      <span class="empty" />
      <span class="label">{{ item[this.imgIndex].label1 }}</span>
      <div class="box">
        <span
          class="boxitem"
          v-for="(skuItem, skuIndex) in item[this.imgIndex].skuList"
          :key="skuIndex"
          @click="selectspecs(skuItem.id, skuItem.imgIndex)"
          :class="{ 'boxitem-checked': skuSelIndex == skuIndex }"
          >{{ skuItem.name }}</span
        >
      </div>
      <span class="label">{{ item[this.imgIndex].label2 }}</span>
      <div class="box">
        <span
          class="boxitem"
          v-for="(skuItem, skuIndex) in item[this.imgIndex].skuSizeList"
          :key="skuIndex"
          @click="selectSize(skuItem.id)"
          :class="{ 'boxitem-checked': skuSizeIndex == skuIndex }"
          >{{ skuItem.name }}</span
        >
      </div>
      <span class="label">{{ item[this.imgIndex].label3 }}</span>
      <div class="box">
        <span
          class="boxitem"
          v-for="(skuItem, skuIndex) in item[this.imgIndex].skuTypeList"
          :key="skuIndex"
          @click="selectType(skuItem.id)"
          :class="{ 'boxitem-checked': skuTypeIndex == skuIndex }"
          >{{ skuItem.name }}</span
        >
      </div>
      <span class="empty" />

      <div class="box">
        <button class="btn">
          <img class="btnimg" src="./imgs/jingdong.png" />
          京东
        </button>
        <button class="btn" @click="gotoTianMao">
          <img class="btnimg" src="./imgs/tianmao.png" />
          天猫
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      prdimgList: [
        { id: 0, img: require("./imgs/section2-prd-1.png"), skuIndex: 0 },
        { id: 1, img: require("./imgs/section2-prd-2.png"), skuIndex: 1 },
        { id: 2, img: require("./imgs/section2-prd-3.png"), skuIndex: 2 },
        { id: 3, img: require("./imgs/section2-prd-5.png"), skuIndex: 2 },
        { id: 4, img: require("./imgs/section2-prd-6.png"), skuIndex: 0 },
        { id: 5, img: require("./imgs/section2-prd-7.png"), skuIndex: 1 },
        // {id: 3, img: require("./imgs/section2-prd-4.png")}
      ],
      item: [
        {
          title: "洗衣机清洁剂",
          label: "产品特点",
          content:
            "专研不同水质不同配方，更好地溶解特定残留物。7效合1——灭活流感、冠状病毒, 杀灭细菌、杀灭真菌、筒壁除垢、祛除内筒异味、清洁内筒，一瓶解决多种洗衣机清洁卫生问题。",
          title1: "专业灭毒：",
          content1: "有效灭活流感病毒（H1N1）、冠状病毒（Hcov-229E）",
          title2: "专业杀菌：",
          content2: "杀菌率>99.9%",
          title3: "精准洁净：",
          content3:
            "不同水质不同配方，有效解决不同水质中矿物沉积不同而带来的水垢差异，保证内筒的清洁效果。",
          title4: "留香祛味：",
          content4: "法国调香大师赋予白棉清芬，留香祛味",
          tip: "数据来自有资质的第三方检测机构，在实验条件下，对H1N1流感病毒、Hcov-229E冠状病毒灭活，符合消毒合格规定。(参照《消毒技术规范2002年版》)。",
          tip1:
            "对大肠杆菌、金黄色葡萄球菌、铜绿假单胞菌、白色念珠菌均具有杀灭作用。",
          tip2: "*采用国标检验方法，数据在实验条件下取得。",
          label1: "产品规格",
          skuList: [
            { id: 0, name: "TDS < 250", imgIndex: 0 },
            { id: 1, name: "TDS200-450", imgIndex: 1 },
            { id: 2, name: "TDS > 400", imgIndex: 2 }
          ],
          label2: "产品容量",
          skuSizeList: [{ id: 0, name: "250ml/瓶" }],
          label3: "产品香型",
          skuTypeList: [{ id: 0, name: "白棉清芬" }]
        },
        {
          title: "洗衣机清洁剂2",
          label: "产品特点",
          content:
            "专研不同水质不同配方，更好地溶解特定残留物。7效合1——灭活流感、冠状病毒, 杀灭细菌、杀灭真菌、筒壁除垢、祛除内筒异味、清洁内筒，一瓶解决多种洗衣机清洁卫生问题。",
          title1: "专业灭毒：",
          content1: "有效灭活流感病毒（H1N1）、冠状病毒（Hcov-229E）",
          title2: "专业杀菌：",
          content2: "杀菌率>99.9%",
          title3: "精准洁净：",
          content3:
            "不同水质不同配方，有效解决不同水质中矿物沉积不同而带来的水垢差异，保证内筒的清洁效果。",
          title4: "留香祛味：",
          content4: "法国调香大师赋予白棉清芬，留香祛味",
          tip: "数据来自有资质的第三方检测机构，在实验条件下，对H1N1流感病毒、Hcov-229E冠状病毒灭活，符合消毒合格规定。(参照《消毒技术规范2002年版》)。",
          tip1:
            "对大肠杆菌、金黄色葡萄球菌、铜绿假单胞菌、白色念珠菌均具有杀灭作用。",
          tip2: "*采用国标检验方法，数据在实验条件下取得。",
          label1: "产品规格",
          skuList: [
            { id: 0, name: "TDS < 250", imgIndex: 0 },
            { id: 1, name: "TDS200-450", imgIndex: 1 },
            { id: 2, name: "TDS > 400", imgIndex: 2 }
          ],
          label2: "产品容量",
          skuSizeList: [{ id: 0, name: "250ml/瓶" }],
          label3: "产品香型",
          skuTypeList: [{ id: 0, name: "白棉清芬" }]
        },
        {
          title: "洗衣机清洁剂3",
          label: "产品特点",
          content:
            "专研不同水质不同配方，更好地溶解特定残留物。7效合1——灭活流感、冠状病毒, 杀灭细菌、杀灭真菌、筒壁除垢、祛除内筒异味、清洁内筒，一瓶解决多种洗衣机清洁卫生问题。",
          title1: "专业灭毒：",
          content1: "有效灭活流感病毒（H1N1）、冠状病毒（Hcov-229E）",
          title2: "专业杀菌：",
          content2: "杀菌率>99.9%",
          title3: "精准洁净：",
          content3:
            "不同水质不同配方，有效解决不同水质中矿物沉积不同而带来的水垢差异，保证内筒的清洁效果。",
          title4: "留香祛味：",
          content4: "法国调香大师赋予白棉清芬，留香祛味",
          tip: "数据来自有资质的第三方检测机构，在实验条件下，对H1N1流感病毒、Hcov-229E冠状病毒灭活，符合消毒合格规定。(参照《消毒技术规范2002年版》)。",
          tip1:
            "对大肠杆菌、金黄色葡萄球菌、铜绿假单胞菌、白色念珠菌均具有杀灭作用。",
          tip2: "*采用国标检验方法，数据在实验条件下取得。",
          label1: "产品规格",
          skuList: [
            { id: 0, name: "TDS < 250", imgIndex: 0 },
            { id: 1, name: "TDS200-450", imgIndex: 1 },
            { id: 2, name: "TDS > 400", imgIndex: 2 }
          ],
          label2: "产品容量",
          skuSizeList: [{ id: 0, name: "250ml/瓶" }],
          label3: "产品香型",
          skuTypeList: [{ id: 0, name: "白棉清芬" }]
        },
        {
          title: "洗衣机清洁剂4",
          label: "产品特点",
          content:
            "专研不同水质不同配方，针对性溶解特定残留物。6效合1——灭活流感病毒, 杀细菌、杀真菌、筒壁除垢、祛除内筒异味、清洁内筒，一瓶解决多种洗衣机清洁卫生问题。",
          title1: "专业灭毒：",
          content1: "有效灭活流感病毒（H1N1）",
          title2: "专业杀菌：",
          content2: "杀菌率>99.9%",
          title3: "精准洁净：",
          content3:
            "不同水质不同配方，有效解决不同水质中矿物沉积不同而带来的水垢差异，保证内筒的清洁效果。",
          title4: "留香祛味：",
          content4: "法国调香大师赋予柠夏海风，自然清新",
          tip: "数据来自有资质的第三方检测机构，在实验条件下，对H1N1流感病毒灭活，符合消毒合格规定。(参照《消毒技术规范2002年版》)。",
          tip1:
            "对大肠杆菌、金黄色葡萄球菌、铜绿假单胞菌、白色念珠菌均具有杀菌作用。",
          tip2: "*采用国标检验方法，数据在实验条件下取得。",
          label1: "产品规格",
          skuList: [
            { id: 0, name: "TDS < 250", imgIndex: 4 },
            { id: 1, name: "TDS200-450", imgIndex: 5 },
            { id: 2, name: "TDS > 400", imgIndex: 3 }
          ],
          label2: "产品容量",
          skuSizeList: [{ id: 0, name: "250ml/瓶" }],
          label3: "产品香型",
          skuTypeList: [{ id: 0, name: "柠夏海风" }]
        },
        {
          title: "洗衣机清洁剂5",
          label: "产品特点",
          content:
            "专研不同水质不同配方，针对性溶解特定残留物。6效合1——灭活流感病毒, 杀细菌、杀真菌、筒壁除垢、祛除内筒异味、清洁内筒，一瓶解决多种洗衣机清洁卫生问题。",
          title1: "专业灭毒：",
          content1: "有效灭活流感病毒（H1N1）",
          title2: "专业杀菌：",
          content2: "杀菌率>99.9%",
          title3: "精准洁净：",
          content3:
            "不同水质不同配方，有效解决不同水质中矿物沉积不同而带来的水垢差异，保证内筒的清洁效果。",
          title4: "留香祛味：",
          content4: "法国调香大师赋予柠夏海风，自然清新",
          tip: "数据来自有资质的第三方检测机构，在实验条件下，对H1N1流感病毒灭活，符合消毒合格规定。(参照《消毒技术规范2002年版》)。",
          tip1:
            "对大肠杆菌、金黄色葡萄球菌、铜绿假单胞菌、白色念珠菌均具有杀菌作用。",
          tip2: "*采用国标检验方法，数据在实验条件下取得。",
          label1: "产品规格",
          skuList: [
            { id: 0, name: "TDS < 250", imgIndex: 4 },
            { id: 1, name: "TDS200-450", imgIndex: 5 },
            { id: 2, name: "TDS > 400", imgIndex: 3 }
          ],
          label2: "产品容量",
          skuSizeList: [{ id: 0, name: "250ml/瓶" }],
          label3: "产品香型",
          skuTypeList: [{ id: 0, name: "柠夏海风" }]
        },
        {
          title: "洗衣机清洁剂6",
          label: "产品特点",
          content:
            "专研不同水质不同配方，针对性溶解特定残留物。6效合1——灭活流感病毒, 杀细菌、杀真菌、筒壁除垢、祛除内筒异味、清洁内筒，一瓶解决多种洗衣机清洁卫生问题。",
          title1: "专业灭毒：",
          content1: "有效灭活流感病毒（H1N1）",
          title2: "专业杀菌：",
          content2: "杀菌率>99.9%",
          title3: "精准洁净：",
          content3:
            "不同水质不同配方，有效解决不同水质中矿物沉积不同而带来的水垢差异，保证内筒的清洁效果。",
          title4: "留香祛味：",
          content4: "法国调香大师赋予柠夏海风，自然清新",
          tip: "数据来自有资质的第三方检测机构，在实验条件下，对H1N1流感病毒灭活，符合消毒合格规定。(参照《消毒技术规范2002年版》)。",
          tip1:
            "对大肠杆菌、金黄色葡萄球菌、铜绿假单胞菌、白色念珠菌均具有杀菌作用。",
          tip2: "*采用国标检验方法，数据在实验条件下取得。",
          label1: "产品规格",
          skuList: [
            { id: 0, name: "TDS < 250", imgIndex: 4 },
            { id: 1, name: "TDS200-450", imgIndex: 5 },
            { id: 2, name: "TDS > 400", imgIndex: 3 }
          ],
          label2: "产品容量",
          skuSizeList: [{ id: 0, name: "250ml/瓶" }],
          label3: "产品香型",
          skuTypeList: [{ id: 0, name: "柠夏海风" }]
        },
      ],
      imgIndex: 0,
      skuSelIndex: 0,
      skuSizeIndex: -1,
      skuTypeIndex: -1
    };
  },

  methods: {
    selectimg(imgIndex, skuIndex) {
      this.imgIndex = imgIndex;
      this.skuSelIndex = -1;
      this.skuSizeIndex = -1;
      this.skuTypeIndex = -1;
      this.skuSelIndex = skuIndex;
    },
    selectspecs(num, imgIndex) {
      this.skuSelIndex = num;
      this.imgIndex = imgIndex;
    },
    selectSize(num) {
      this.skuSizeIndex = num;
    },
    selectType(num) {
      this.skuTypeIndex = num;
    },
    gotoTianMao() {
      window.open("https://u2xihu.tmall.com");
    }
  }
};
</script>

<style lang="scss" scoped>
.section2 {
  position: relative;
  background: #fff;
  height: 7.2rem;
  margin-top: 0.3rem;
  .prdimgs {
    position: absolute;
    left: 2.5rem;
    width: 1.5rem;
    height: 6.6rem;
    overflow-y: auto;

    .prdimgitem {
      width: 65%;
      border: 1px solid #e5e5e5;
      padding: 0.2rem;

      &:hover {
        border: 1px solid #000000;
      }

      &:focus {
        border: 1px solid #000000;
      }
    }
    .prdimgitem-checked {
      border: 1px solid #000000;
    }
  }

  .prdtext {
    width: 3rem;
    position: absolute;
    left: 4.2rem;
    color: #212121;
    display: grid;
    align-content: center;
    justify-content: start;
    align-items: center;
    justify-items: start;

    .title {
      font-size: 0.12rem;
      line-height: 0.3rem;
    }

    .label {
      color: #dfb25f;
      font-size: 0.08rem;
      line-height: 0.3rem;
    }

    .content {
      color: #212121;
      font-size: 0.08rem;
      width: 100%;
    }

    .tip {
      color: #666666;
      font-size: 0.06rem;
      width: 100%;
    }

    .empty {
      height: 0.2rem;
    }

    .box {
      color: #999999;
      font-size: 0.07rem;
      line-height: 0.3rem;

      .boxitem {
        border: 1px solid #999999;
        padding: 0.06rem 0.15rem;
        border-radius: 10px;
        margin-right: 0.1rem;
        cursor: pointer;
        &:hover {
          background: #dfb25f;
          border: 1px solid #dfb25f;
          color: #fff;
        }
      }

      .boxitem-checked {
        background: #dfb25f;
        border: 1px solid #dfb25f;
        color: #fff;
      }

      .btn {
        background: #e5e5e5;
        border: 1px solid #e5e5e5;
        padding: 0.06rem 0.24rem;
        border-radius: 10px;
        margin-right: 0.1rem;
        cursor: pointer;

        // background-image: url('./imgs/jingdong.png'); /* 设置按钮背景图片 */
        // background-repeat: no-repeat; /* 不重复背景图片 */
        // background-position: left center; /* 图片在按钮中左侧居中 */
        // padding-left: 0.2rem; /* 按钮文本向右偏移，以便留出图片空间 */
        // padding-right: 0.2rem; /* 可选：为文本提供一些空间 */
        // border: none; /* 无边框 */
        // height: 0.3rem; /* 按钮高度 */

        &:hover {
          background: #b2b2b2;
          color: #fff;
        }
      }

      .btnimg {
        height: 0.1rem;
        margin-right: 8px;
        vertical-align: middle;
      }
    }

    .spanhover {
      border: 1px solid #999999;
      padding: 0.06rem 0.15rem;
      border-radius: 10px;
      margin-right: 0.1rem;
      cursor: pointer;

      background: #dfb25f;
      border: 1px solid #dfb25f;
      color: #fff;
    }

    .imgprdimgitem {
      width: 65%;
      border: 1px solid #e5e5e5;
      padding: 0.2rem;

      border: 1px solid #000000;
    }
  }
}
</style>
