<template>
  <div class="section1">
    <Header></Header>
    <div class="banner">
      <img
        src="./imgs/lianxi.png"
        class="lianxi animationLianxi"
        id="elementToAnimate"
      />
    </div>
  </div>
</template>

<script>
import Header from "../Header";

export default {
  components: {
    Header
  }
};
</script>

<style lang="scss" scoped>
.section1 {
  position: relative;
  height: 5.4rem;
  .banner {
    position: absolute;
    top: 0.8rem;
    left: 0;
    right: 0;
    height: 4.6rem;
    background: url(./imgs/section1-bg.png) no-repeat;
    background-size: 100% 100%;
  }

  .lianxi {
    width: 40%;
    position: absolute;
    top: 0.7rem;
    left: 30%;
    opacity: 0;
  }

  .animationLianxi {
    animation: move-text 3s forwards;
  }

  @keyframes move-text {
    0% {
      transform: translateY(0);
      opacity: 0;
    }
    100% {
      transform: translateY(40px);
      opacity: 1;
    }
    to {
      opacity: 1;
    }
  }
}
</style>
